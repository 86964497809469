import "./App.css"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { LoginPage } from "./pages/Login/LoginPage"
import { FlashingStation } from "./pages/FlashingStation/FlashingStationPage"
import { PrivateRoute } from "./pages/Login/PrivateRoute"
import { BootstrappingPage } from "./pages/Bootstrapping/BootstrapPage"
import { useEffect } from "react"

function App() {
  const token = localStorage.getItem("token")

  useEffect(() => {
    if (!token && window.location.pathname !== "/") {
      window.location.href = "/"
    }
  }, [token])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route
          path="/flashingstation"
          element={
            <PrivateRoute>
              <FlashingStation />
            </PrivateRoute>
          }
        />
        <Route
          path="/bootstrapping"
          element={
            <PrivateRoute>
              <BootstrappingPage />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default App
